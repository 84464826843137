<template>
    <div>
        <header class="w-100 navbar_top" style="">
            <div class="w-100 d-flex justify-content-between align-items-center px-4 navbar_top_inner">
                <div>
                    <router-link to="/">
                        <img src="../assets/logo/AP_Logo.webp" alt="logo" class="logo mx-md-5">
                    </router-link>
                </div>
                <button class="text-white menu_btn" @click="toggleNav()">
                    <b-icon icon="justify"></b-icon>
                </button>
                <!-- <div class="mx-4 menu_top" :class="blackNavColor == 'black'?'navText_black':'navText_white'"> -->
                <div class="mx-4 menu_top text-white">
                    <router-link class="mx-4 py-2" id="contact_us" :to="{name:'contact'}">STUDIOS</router-link>
                    <!-- <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link> -->
                    <router-link class="mx-4 py-2" id="careers" :to="{name:'careers'}">CAREERS</router-link>
                    <router-link class="mx-4 py-2" id="about" :to="{name:'about'}">CULTURE</router-link>
                    <router-link class="mx-4 py-2" id="insights" :to="{name:'insight'}">RESEARCH AND IDEAS</router-link>
                    <router-link class="mx-4 py-2" id="projects" :to="{name:'projects'}">EXPERTISE</router-link>
                    <router-link class="mx-4 py-2" id="career" :to="{name:'home'}">HOME</router-link>
                </div>
            </div>
            <div id="mySidenav" class="sidenav">
                <button class="closebtn bg-transparent text-white" @click="closeNav()">&times;</button>
                <router-link class="mx-4 py-2" id="career" :to="{name:'home'}" >HOME</router-link>
                <router-link class="mx-4 py-2" id="projects" :to="{name:'projects'}">EXPERTISE</router-link>
                <router-link class="mx-4 py-2" id="insights" :to="{name:'insight'}">RESEARCH AND IDEAS</router-link>
                <router-link class="mx-4 py-2" id="about" :to="{name:'about'}">CULTURE</router-link>
                <!-- <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link> -->
                <router-link class="mx-4 py-2" id="careers" :to="{name:'careers'}">CAREERS</router-link>
                <router-link class="mx-4 py-2" id="contact_us" :to="{name:'contact'}">STUDIOS</router-link>
            </div>
        </header>
        <header id="navbar_scroll" class="w-100 navbar_top">
            <div class="w-100 d-flex justify-content-between align-items-center px-4 navbar_top_inner">
                <div>
                    <router-link to="/">
                        <img src="../assets/logo/AP_Logo.webp" alt="logo" class="logo mx-md-5">
                    </router-link>
                </div>
                <button class="text-white menu_btn" @click="toggleNav()">
                    <b-icon icon="justify"></b-icon>
                </button>
                <!-- <div class="mx-4 menu_top" :class="blackNavColor == 'black'?'navText_black':'navText_white'"> -->
                <div class="mx-4 menu_top text-white">
                    <router-link class="mx-4 py-2" id="contact_us" :to="{name:'contact'}">STUDIOS</router-link>
                    <!-- <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link> -->
                    <router-link class="mx-4 py-2" id="careers" :to="{name:'careers'}">CAREERS</router-link>
                    <router-link class="mx-4 py-2" id="about" :to="{name:'about'}">CULTURE</router-link>
                    <router-link class="mx-4 py-2" id="insights" :to="{name:'insight'}">RESEARCH AND IDEAS</router-link>
                    <router-link class="mx-4 py-2" id="projects" :to="{name:'projects'}">EXPERTISE</router-link>
                    <router-link class="mx-4 py-2" id="career" :to="{name:'home'}">HOME</router-link>
                </div>
            </div>
            <div id="mySidenav" class="sidenav">
                <button class="closebtn" @click="closeNav()">&times;</button>
                <router-link class="mx-4 py-2" id="career" to="/">HOME</router-link>
                <router-link class="mx-4 py-2" id="projects" to="project">EXPERTISE</router-link>
                <router-link class="mx-4 py-2" id="insights" to="insight">RESEARCH AND IDEAS</router-link>
                <router-link class="mx-4 py-2" id="about" to="about">CULTURE</router-link>
                <!-- <router-link class="mx-4 py-2" id="metaverse" to="/metaverse">METAVERSE</router-link> -->
                <router-link class="mx-4 py-2" id="careers" to="careers">CAREERS</router-link>
                <router-link class="mx-4 py-2" id="contact_us" to="contact">STUDIOS</router-link>
            </div>
        </header>
    </div>
    
</template>

<script>
export default {
    name:'navbar',
    props:['blackNavColor'],
    data(){
        return {
            navColor:false
        }
    },
    mounted() {
        // if (this.$route.name == "career") {
        //     this.navColor = !this.navColor;
        // }
        window.onscroll = function(){scrollFunction()};

        function scrollFunction() {
            if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
                document.getElementById("navbar_scroll").style.top = "0";
            } else {
                document.getElementById("navbar_scroll").style.top = "-150px";
            }
        }
    },
    methods: {
        toggleNav() {
            console.log(document.getElementById("mySidenav").style.width == "250px")
            if(document.getElementById("mySidenav").style.width == "250px"){
                document.getElementById("mySidenav").style.width = "0";
            }else{
                document.getElementById("mySidenav").style.width = "250px";
            }
        },

        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
        }
    }
}
</script>

<style>
    #navbar_scroll{
        position: fixed;
        top: -150px;
        width: 100%;
        display: block;
        transition: top 0.5s;
        z-index: 999;
        background-color: #0d0d0d;
    }
    #mySidenav{
        height: 100vh;
    }
    .nav_ul li{
        margin: 0 1rem;
    }
    .nav_ul li img{
        object-fit: contain;
    }
    .firm_dropdown {
    position: relative;
    display: inline-block;
    }
    .firm_dropdown-content li:hover a, .firm_dropdown-content li:focus a {
        color:var(--secondColor) !important;
    }
    .firm_dropdown-content {
    display: none;
    position: absolute;
    background: rgba(242, 242, 242, 0.913) !important;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
    border-radius: .5rem;
    right: 0;
    }
    .firm_dropdown-content li a{
        transition: all .3s ease;
    }
    .firm_dropdown-content li {
        display: flex;
        margin: .25rem 0;
    }
    .firm_dropdown{
        cursor: pointer;
    }
    .firm_dropdown:hover .firm_dropdown-content {
    display: block;
    }
    .logo{
        width: 8rem;
        height: 7rem;
        object-fit: contain;
    }
    .navbar_top_inner .menu_btn{
        display: none;
    }
    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
        border-color: transparent !important;
    }
    .bi-caret-down-fill{
        font-size: 12px !important;
    }
    .btn {
        --bs-btn-padding-x: 0.75rem;
        --bs-btn-padding-y: 0.375rem;
    }
    #career, #projects,#metaverse, #firm,#insights, #contact_us, #about,#careers{
        border-bottom: 2px solid transparent;
        font-weight: 500;
        color:white;
        transition: all .3s ease;
        letter-spacing: 2px;
        font-size: 14px;
    }

    #career:hover, #projects:hover, #metaverse:hover, #firm:hover,#insights:hover, #contact_us:hover, #about:hover, #careers:hover{
        border-bottom: 2px solid white;
    }
    .navbar_top{
        position: absolute;
        top: 0;
        /* background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); */
        color: #f1f1f1;
        z-index: 9999;
        background: -webkit-linear-gradient(270deg, rgb(0 0 0 / 80%) 0%, rgb(255 255 255 / 0%) 100%);
    }
    .navbar_top_inner{
        filter: drop-shadow(rgb(90, 90, 90) 3px 6px 5px);
    }
    .dropdown-menu-right{
        background: rgba(0, 0, 0, 0.542) !important;
    }
    .dropdown-item{
        color: #f1f1f1 !important;
        transition: all .3s ease;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color:var(--secondColor) !important;
        background-color: transparent !important;
    }
    .menu_btn{
        background-color: transparent;
        font-size: 2rem;
    }
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        
    }

    .sidenav a, .sidenav .side_dropdown {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 17px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

    .sidenav a:hover {
        color: #f1f1f1;
    }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }
    .side_dropdown .dropdown-menu{
        position: relative !important;
        transform: translate3d(0px, 0px, 0px) !important;
        background-color: transparent;
    }
    .navText_black a,.navText_black span,.navText_black svg{
        color: black !important;
    }
    .navText_black .dropdown-item{
        color:white ;
    }
    .navText_black .dropdown-menu-right {
        background: rgba(255, 255, 255, 0.974) !important;
    }
    .navText_black #career:hover, .navText_black #projects:hover, .navText_black #metaverse:hover, .navText_black #firm:hover,.navText_black #insights:hover, .navText_black #contact_us:hover,.navText_black #about:hover, .navText_black #careers:hover{
        border-bottom: 2px solid black !important;
    }


    .navText_white a,.navText_white span,.navText_white svg{
        color: white !important;
    }
    .navText_white .dropdown-item{
        color:black ;
    }
    .navText_white .dropdown-menu-right {
        background: rgba(53, 53, 53, 0.974) !important;
    }
    .navText_white #career:hover, .navText_white #projects:hover, .navText_white #metaverse:hover, .navText_white #firm:hover,.navText_white #insights:hover, .navText_white #contact_us:hover,.navText_white #insights:hover, .navText_white #about:hover, .navText_white #careers:hover{
        border-bottom: 2px solid white !important;
    }
    .menu_top{
        display: flex;
        flex-direction: row-reverse;
    }
@media only screen and (max-width: 768px) {
    .navbar_top_inner .menu_top{
        display: none;
    }
    .navbar_top_inner .menu_btn{
        display: block;
    }
    #career:hover, #projects:hover, #metaverse:hover, #firm:hover,#insights, #contact_us:hover, #about:hover, #careers:hover{
        border-bottom: 2px solid transparent;
    }
}
</style>
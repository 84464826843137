<template>
    <section id="banner_part">

        <video playsinline autoplay muted loop id="bannerVideo">
            <source src="../assets/image/banner_video.mp4" type="video/mp4">
            Your browser does not support HTML5 video.
        </video>
    </section>
</template>

<script>
import 'animate.css';
export default {
    name:'banner',
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{
       
    }
}
</script>

<style>
    #bannerVideo{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        /* position: fixed; */
        top: 0;
        left: 0;
        filter: grayscale(1);
    }
    @import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:wght@100;300;400;700;900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
    #banner_part::after{
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: -webkit-linear-gradient(23deg, #000000a6 0%, rgb(255 255 255 / 12%) 100%);
    }
    
    @media only screen and (max-width: 640px) {
        
    }

</style>

<template>
    <section class="about_part section-padding">
        <div class="container">
            <div class="row">
                <div class="section_tittle px-4 mb-3">
                    <h2><span>ABOUT US</span></h2>
                </div>
            </div>
            <div class="d-lg-flex mt-4">
                <div class="col-lg-5 my-auto">
                    <div class="about_img">
                        <img src="../assets/image/about.webp" alt="">
                    </div>
                </div>
                <div class="offset-lg-1 col-lg-6 col-sm-12  d-flex justify-content-center align-items-start">
                    <div class="about_text my-5 my-lg-0 wrap">
                        <!-- <div class="mb-md-4 d-flex justify-content-md-start justify-content-center text-start">
                            <h1>SPACES WHERE PEOPLE<br><span style="color:var(--secondColor)"> LIVE. WORK. CONNECT.</span></h1>
                        </div> -->
                        <div class="w-100 w-sm-100 py-3 px-2 p-md-0 read-more" id="about_us_main" style="text-align: justify;" >
                            <p class="my-0 content fs-6" style="line-height: 1.9rem;">
                                Unity Architects, an Atlanta-based full-service Architectural and engineering firm, was founded in 2017 and rebranded from ArchiPars in 2024. We specialize in delivering innovative design solutions that streamline project timelines and reduce costs for clients. Our highly skilled team is committed to creating detailed, accurate drawings that expedite permitting—often obtaining approval in the first round of submission—saving both time and money. Whether it’s residential, commercial, or mixed-use projects, we deliver excellence at every stage, from concept through completion, ensuring each project is functional, aesthetically superior, and sustainable.
                                Unity Architects stands as a well-established and structured firm, built on a foundation of innovation, precision, and collaboration. Originally founded as ArchiPars, we’ve expanded our services and capabilities, becoming a trusted partner for a diverse group of clients across a variety of industries. Our team of experienced professionals brings together expertise in Architectural design, engineering, interior design, and project management, allowing us to handle projects of any size and scope with ease.
                                Our mission is clear: to provide meticulously tailored Architectural and engineering solutions that address the specific needs of clients in both the Southeastern United States as well as our global markets. We pride ourselves on producing high-quality designs that not only exceed client expectations but also contribute positively to the environments and communities in which we work.
                                The rebranding to Unity Architects reflects our commitment to uniting diverse perspectives in Architecture. We draw inspiration from various cultures and design philosophies, ensuring inclusivity in our approach. Our dedication to sustainability, innovation, and client satisfaction is central to our work, and we strive to set new benchmarks within the industry.
                                Led by founder Ali R. Najafi, Unity Architects combines his background in Persian Architectural traditions with cutting-edge techniques to deliver spaces that are not only aesthetically remarkable but also functional and efficient. With a strong focus on precision, our firm excels at producing drawings that move swiftly through the permitting process, minimizing delays and reducing costs. This attention to detail, paired with our collaborative approach, ensures that every project is delivered on time and within budget.
                                Our vision is to position Unity Architects as a top-tier firm, recognized in the Southeastern United States and its global markets for leadership in Architecture and engineering. We aim to shape the future of Architecture through our commitment to quality, innovation, and sustainable community impact. At Unity Architects, we are more than just designers—we are partners in creating spaces that inspire and endure.
                            </p>
                            <div class="w-100 d-flex justify-content-start align-items-center mt-3" >
                                <button class="py-2 px-4 rounded-2 bg-dark text-white mt-2 trigger" @click="toggleAboutUs">
                                    Read more
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import 'animate.css';
export default {
    name:'about_us',
    methods:{
        toggleAboutUs(){
            const mainDiv = document.getElementById('about_us_main');
            const existExpandDiv = document.querySelector('.expanded');
            if (existExpandDiv) {
                mainDiv.classList.remove('expanded');
            }else{                
                mainDiv.classList.add('expanded');
            }
        }
    }
}
</script>

<style>

.read-more {
  position: relative;
  color: #34495e; /* text-color */
  text-decoration: none;
  cursor: default;
}

.read-more .content {
  position: relative;
  overflow: hidden;
  max-height: calc(4rem * 1.4 * 4); /* Optimal height based on font-size, line-height, and rows */
  transition: max-height 500ms ease;
}

.read-more .content::before {
  content: "";
  background: linear-gradient(to bottom, #fff0, #ffffffab 60%, #fff);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc((4rem * 1.4 * 4) / 2); /* Half of read-more height */
  transition: opacity 500ms ease, visibility 500ms ease;
}

.read-more.expanded .content {
  max-height:1000px;
}

.read-more.expanded .content::before,
.read-more.expanded .trigger {
  opacity: 0;
  visibility: hidden;
}






#about_us{
    width: 100%;
    height: 85%;
}
.about_part{
    position: relative;
    z-index: 99;
}
.about_part{
    padding: 100px 0px;
}
.section_tittle h2 {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    line-height: 24px;
    display: flex;
    padding-left: 195px
}
.section_tittle h2:after {
    position: absolute;
    content: "";
    left: 0;
    top: 12px;
    width: 180px;
    height: 1px;
    background-color: #000000;
}
.about_part .about_img{
    position: relative;
    z-index: 1;
}
.about_img img {
    max-width: 100%;
    height: 42rem;
}
.about_img:after {
    position: absolute;
    content: "";
    /* width: 25%; */
    width: 50%;
    /* height: 35rem; */
    height: 100%;
    /* top: 70rem; */
    top: 55px;
    /* left: 24rem; */
    right: 0;
    background-image: url(../assets/image/about_back.webp);
    z-index: -1;
}
.about_text{
    display: grid;
    justify-content: center;
    align-items: center;
}
.about_text h1{
    font-weight: 600;
    line-height: 3.5rem;
}
.about_part p{
    font-size: 20px;
    line-height: 1.8rem;
    /* overflow: hidden; */
    /* -webkit-box-orient: vertical; */
    display: block;
    /* display: -webkit-box; */
    /* overflow: hidden !important; */
    /* text-overflow: ellipsis; */
    /* -webkit-line-clamp: 6; */
    text-align: justify;
}
@media only screen and (max-width: 768px) {
    .read-more.expanded .content {
        max-height: 1800px;
    }
}
@media only screen and (max-width: 640px) {
    .about_part {
        padding: 10px 0px;
    }
    .about_img img {
        max-width: 100%;
        height: 20rem;
    }
    .about_img:after {
        right: 2rem;
        top: 2rem;
    }
    .logo_footer {
        width: 100%;
    }
    .w-sm-100{
        width: 100% !important;
    }
    .read-more.expanded .content {
        max-height: 1800px;
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    props: true,
  },
  {
    path: '/culture',
    name: 'about',
    component: () => import(/* webpackChunkName: "Culture" */ '../views/About.vue'),
    props: true,
  },
  // {
  //   path: '/metaverse',
  //   name: 'metaverse',
  //   component: () => import(/* webpackChunkName: "metaverse" */ '../views/Metaverse.vue'),
  //   props: true,
  // },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "Careers" */ '../views/Careers.vue'),
    props: true,
  },
  {
    path: '/studios',
    name: 'contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    props: true,
  },
  {
    path: '/research_and_ideas',
    name: 'insight',
    component: () => import(/* webpackChunkName: "Insight" */ '../views/Insight.vue'),
    props: true,
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import(/* webpackChunkName: "Article" */ '../views/Article.vue'),
    props: true,
  },
  {
    path: '/service/',
    name: 'service',
    component: () => import(/* webpackChunkName: "Service" */ '../views/Service.vue'),
    props: true,
  },
  {
    path: '/service/:service_id/:service_name',
    name: 'single_service',
    component: () => import(/* webpackChunkName: "Single_service" */ '../views/Single_service.vue'),
    props: true,
  },
  {
    path: '/expertise/:project_type/:project_id/:project_name',
    name: 'single_project',
    component: () => import(/* webpackChunkName: "Single_project" */ '../views/Single_project.vue'),
    props: true,
  },
  {
    path: '/expertise',
    name: 'projects',
    component: () => import(/* webpackChunkName: "Project" */ '../views/Projects.vue'),
    props: true,
  },
  {
    path: '/expertise/luxury_homes',
    name: 'luxury_projects',
    component: () => import(/* webpackChunkName: "Luxury_projects" */ '../views/Luxury_projects.vue'),
    props: true,
  },
  {
    path: '/expertise/commercial',
    name: 'Commercial_projects',
    component: () => import(/* webpackChunkName: "Commercial_projects" */ '../views/Commercial_projects.vue'),
    props: true,
  },
  {
    path: '/expertise/institutional',
    name: 'Institutional_projects',
    component: () => import(/* webpackChunkName: "Institutional_projects" */ '../views/Institutional_projects.vue'),
    props: true,
  },
  {
    path: '/expertise/retail',
    name: 'Retail_projects',
    component: () => import(/* webpackChunkName: "Retail_projects" */ '../views/Retail_projects.vue'),
    props: true,
  },
  {
    path: '/expertise/hospitality',
    name: 'Hospitality_projects',
    component: () => import(/* webpackChunkName: "Hospitality_projects" */ '../views/Hospitality_projects.vue'),
    props: true,
  },
  {
    path: '/expertise/community',
    name: 'Community_projects',
    component: () => import(/* webpackChunkName: "Community_projects" */ '../views/Community_projects.vue'),
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/Not_found.vue')
  },
  {
    path: '/notfound',
    name: 'not_found',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/Not_found.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: './',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

export default router
